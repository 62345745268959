<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-07-05 09:52:23
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-07-22 16:05:36
 * @FilePath: \official_website\src\views\science\componets\Decipher.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 文献解读 -->
<template>
  <div id="decipher">
    <ul class="news-box">
      <li
        class="news-item"
        v-for="(item, index) in decipherList"
        :key="index"
        @click="tapArticle(item.id)"
      >
        <img class="news-img" :src="item.titleImage" />
        <div class="news-text wow animate__animated animate__fadeInUp">
          {{ item.title }}
        </div>
        <div class="times wow animate__animated animate__fadeInUp">
          {{ item.createTime }}
        </div>
      </li>
    </ul>
    <div class="news-footer">
      <van-button plain color="#0b847b" v-if="decMoreFlag" @click="knowMore"
        >了解更多</van-button
      >
    </div>
    <div class="backTop" v-show="backTopFlag" @click="backTop">
      <i class="iconfont icon-huidaodingbu"></i>
    </div>
  </div>
</template>
    
<script>
import api from "../../../../api/api";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      decipherList: [], // 分页新闻列表
      count: 0, // 新闻列表数量
      currentPage: 1, // 当前页码
      decMoreFlag: true,
      backTopFlag: false,
    };
  },
  created() {
    this.getDecipherList();
    this.getDecipherCounts();
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {
    currentPage: {
      handler(oldValue, newValue) {
        setTimeout(() => {
          if (this.currentPage >= Math.ceil(this.count / 10)) {
            this.decMoreFlag = false;
          }
        }, 300);
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    // 分页获取文献列表
    getDecipherList(page = 1) {
      api
        .getDecipherByPage(page)
        .then((res) => {
          // console.log(res.data);
          if (res.data.code == 200) {
            let result = res.data.models;
            result.forEach((item) => {
              let times = item.createTime ? item.createTime.split(" ")[0] : "";
              let moment = times ? times.split("-") : [];
              item.year = moment[0];
              item.month = moment[1];
              item.day = moment[2];
            });

            if (page > 1) {
              this.decipherList = this.decipherList.concat(result);
            } else {
              this.decipherList = result;
            }
          }
        })
        .catch((error) => {});
    },
    // 获取文献总条数
    getDecipherCounts() {
      api
        .getDecipherCount()
        .then((res) => {
          if (res.data.code == 200) {
            this.count = res.data.models[0];
          }
        })
        .catch((error) => {});
    },
    knowMore() {
      if (this.currentPage < Math.ceil(this.count / 10)) {
        this.currentPage++;
        this.getDecipherList(this.currentPage);
      }
    },
    // 回到顶部
    backTop() {
      this.$nextTick(() => {
        document.getElementById("science").scrollTop = 0;
      });
    },
    tapArticle(id) {
      this.$router.push({
        path: `/decipherDetail/${id}`,
      });
    },
  },
};
</script>
    
<style lang="scss">
#decipher {
  .news-box {
    padding: 0 1.25rem;
    .news-item {
      padding: 1rem 0;
      border-bottom: 1px solid #ddd;
      .news-img {
        width: 100%;
      }
      .news-text {
        padding: 0.8rem 0.5rem;
        font-size: 1rem;
        font-family: "SourceHanSansSC-Medium";
        background-color: #fafafa;
      }
      .times {
        color: #666666;
        padding: 0.5rem;
        font-family: "OPlusSans3-Regular";
        font-size: 0.75rem;
        background-color: #fafafa;
      }
    }
  }
  .news-footer {
    display: flex;
    justify-content: center;
    padding: 1rem 0 2rem;
  }
  .backTop {
    width: 2.25px;
    height: 2.25px;
    background-color: #666;
    border-radius: 50%;
    opacity: 0.8;
    position: fixed;
    right: 1rem;
    bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 1.25rem;
      color: #fff;
    }
  }
}
</style>